import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { DASHBOARD_URL, DELETE_ACCOUNT_SUCCESS_URL } from '../constants/AppUrlConstants';
import Auth from '../utils/Auth';
import handleAuthErrors from '../utils/API/handleAuthErrors';

const ActionEntity = ({ notification }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { entityId } = useParams();
  const [isFormDisabled, setFormDisabled] = useState(false);
  const [error, setError] = useState(null);
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);
  const {
    title, heading, entity, baseURL, redirectURL, apiHookConfig = [], action,
  } = notification;
  const entityURL = entityId ? `${baseURL}/${entityId}` : `${baseURL}`;

  document.title = `${action} ${entity}`;

  const patchData = async ({ dataToSubmit, signal }) => {
    const resp = await axios.patch(entityURL, dataToSubmit, {
      signal,
      headers: { Authorization: `Bearer ${Auth.retrieveToken()}` },
    });
    return resp;
  };

  const deleteItem = async () => {
    const resp = await axios.delete(entityURL, {
      headers: { Authorization: `Bearer ${Auth.retrieveToken()}` },
    });
    return resp;
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    const controller = new AbortController();
    const { signal } = controller;
    setFormDisabled(true);

    if (deletionConfirmed) {
      try {
        if (action === 'Cancel') {
          await patchData({ dataToSubmit: apiHookConfig[0], signal });
          navigate(redirectURL, { state: { title, heading } }, { replace: true });
        } else {
          await deleteItem();
          if (entity === 'account') {
            Auth.logout();
            navigate(DELETE_ACCOUNT_SUCCESS_URL, { state: { title, heading }, replace: true });
          } else {
            navigate(redirectURL, { state: { title, heading } }, { replace: true });
          }
        }
      } catch (err) {
        if (err?.code === 'ERR_CANCELED') {
          return;
        }
        await handleAuthErrors({
          error: err,
          navigate,
          nonAuthRedirectUrl: DASHBOARD_URL,
          redirectUrl: redirectURL,
        });
        setError(`Cannot ${action.toLowerCase()} this ${entity} right now, try again later`);
        setFormDisabled(false);
      }
    } else {
      navigate(state?.redirectOnCancel || redirectURL, { replace: true });
    }
  };

  return (
    <div className="govuk-grid-row">
      <div className="govuk-grid-column-two-thirds">
        {error && (
          <div
            className="govuk-error-summary"
            aria-labelledby="error-summary-title"
            role="alert"
            tabIndex="-1"
            data-module="govuk-error-summary"
          >
            <h2 className="govuk-error-summary__title">{error}</h2>
          </div>
        )}

        <form onSubmit={formSubmitHandler}>
          <div className="govuk-form-group">
            <fieldset className="govuk-fieldset">
              <legend className="govuk-fieldset__legend govuk-fieldset__legend--l">
                <h1 className="govuk-fieldset__heading">
                  {`Are you sure you want to ${action.toLowerCase()} this ${entity}?`}
                </h1>
              </legend>

              <div className="govuk-hint">This action cannot be undone.</div>

              <div className="govuk-radios govuk-radios--inline">
                <div className="govuk-radios__item">
                  <input
                    className="govuk-radios__input"
                    id="confirm-yes"
                    data-testid="confirm-yes"
                    name="confirm_deletion"
                    type="radio"
                    value="yes"
                    onChange={() => setDeletionConfirmed(true)}
                  />
                  <label className="govuk-label govuk-radios__label" htmlFor="confirm-yes">
                    Yes
                  </label>
                </div>
                <div className="govuk-radios__item">
                  <input
                    className="govuk-radios__input"
                    id="confirm-no"
                    data-testid="confirm-no"
                    name="confirm_deletion"
                    type="radio"
                    value="no"
                    onChange={() => setDeletionConfirmed(false)}
                  />
                  <label className="govuk-label govuk-radios__label" htmlFor="confirm-no">
                    No
                  </label>
                </div>
              </div>
            </fieldset>
          </div>

          <button
            disabled={isFormDisabled}
            type="submit"
            className="govuk-button"
            data-module="govuk-button"
          >
            Continue
          </button>
        </form>
      </div>
    </div>
  );
};

export default ActionEntity;

ActionEntity.propTypes = {
  notification: PropTypes.shape({
    title: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    baseURL: PropTypes.string.isRequired,
    redirectURL: PropTypes.string.isRequired,
    apiHookConfig: PropTypes.array,
    action: PropTypes.string.isRequired,
  }).isRequired,
};
