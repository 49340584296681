import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import axios from 'axios';

// App imports
import { LOGOUT_URL } from '../constants/AppApiConstants';
import { SERVICE_NAME } from '../constants/AppConstants';
import Auth from '../utils/Auth';

const Nav = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const serviceName = SERVICE_NAME;
  const [navArray, setNavArray] = useState([]);
  const [menuState, setMenuState] = useState(true);

  const menuToggle = (e) => {
    e.preventDefault();
    setMenuState(!menuState);
  };

  const navData = [
    {
      id: 'VoyagePlans',
      urlStem: '/voyage-plans',
      text: 'Voyage Plans',
      active: false,
    },
    {
      id: 'PleasureCrafts',
      urlStem: '/pleasure-crafts',
      text: 'Pleasure Crafts',
      active: false,
    },
    {
      id: 'People',
      urlStem: '/people',
      text: 'People',
      active: false,
    },
    {
      id: 'Account',
      urlStem: '/account',
      text: 'Account',
      active: false,
    },
    {
      id: 'Help',
      urlStem: '/page/help',
      text: 'Help',
      active: false,
    },
  ];

  const setActivePage = (url) => {
    const tempArr = [...navData];
    const newArray = tempArr.map((elem) => {
      const currentUrl = !url ? elem.urlStem : url;
      if (currentUrl === elem.urlStem) {
        return { ...elem, active: true };
      }
      return { ...elem, active: false };
    });
    document.activeElement.blur();
    setNavArray(newArray);
  };

  const handleSignout = async () => {
    try {
      const response = await axios.post(
        LOGOUT_URL,
        {},
        {
          headers: { Authorization: `Bearer ${Auth.retrieveToken()}` },
        },
      );
      if (response.status === 200) {
        setActivePage('/voyage-plans');
        Auth.logout();
        sessionStorage.clear();
        navigate('/sign-in');
      }
    } catch (err) {
      setActivePage('/voyage-plans');
      Auth.logout();
      navigate('/sign-in');
    } finally {
      sessionStorage.removeItem('page'); // removes the page stored so when user signs in it reverts to page 1
    }
  };

  useEffect(() => {
    setActivePage(pathname);
  }, [pathname]);

  return (
    <div className="govuk-header__content">
      <Link
        className="govuk-header__link govuk-header__service-name"
        to="/"
        data-testid="serviceName"
      >
        {serviceName}
      </Link>

      {Auth.isAuthorized() && (
        <nav aria-label="Menu" className="govuk-header__navigation ">
          <button
            type="button"
            onClick={(e) => menuToggle(e)}
            className={
              !menuState
                ? 'govuk-header__menu-button govuk-js-header-toggle govuk-header__menu-button--close'
                : 'govuk-header__menu-button govuk-js-header-toggle govuk-header__menu-button--open'
            }
            aria-controls="navigation"
            aria-label="Show or hide navigation menu"
            aria-expanded={menuState}
          >
            Menu
          </button>
          <ul
            id="navigation"
            className={
              !menuState
                ? 'govuk-header__navigation-list govuk-visually-hidden'
                : 'govuk-header__navigation-list govuk-header__navigation-list--open'
            }
            aria-label="Top Level Navigation"
          >
            {navArray.map((elem) => {
              const activeState = elem.active
                ? 'govuk-header__navigation-item govuk-header__navigation-item--active'
                : 'govuk-header__navigation-item';
              return (
                <li className={activeState} key={elem.urlStem} data-testid={`listitem-${elem.id}`}>
                  <Link
                    to={elem.urlStem}
                    className="govuk-header__link"
                    onClick={() => setActivePage(elem.urlStem)}
                  >
                    {elem.text}
                  </Link>
                </li>
              );
            })}
            <li className="govuk-header__navigation-item">
              <a href="#sign-out" className="govuk-header__link" onClick={() => handleSignout()}>
                Sign out
              </a>
              {/* Link tag cannot be used as we do not have a signout route */}
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default Nav;
