import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const SkipLink = () => {
  const { pathname } = useLocation();
  const ref = useRef(null);

  // remove once react-router accessibility issue is fixed
  // https://github.com/ReactTraining/react-router/issues/5210
  useEffect(() => {
    ref.current?.focus();
  }, [pathname]);

  const handleSkipLink = (e) => {
    e.preventDefault();
    document.getElementById('main-content').scrollIntoView();
    document.getElementById('main-content').focus();
  };

  return (
    <div className="govuk-grid-full">
      <span ref={ref} tabIndex={-1} data-testid="skip-link" />
      <button
        type="button"
        className="govuk-skip-link"
        id="skip-link"
        onClick={(e) => handleSkipLink(e)}
      >
        Skip to main content
      </button>
    </div>
  );
};

export default SkipLink;
